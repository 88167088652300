import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'


export const GalleryPageTemplate = ({ title, content, contentComponent, images, publicURLs }) => {
  const PageContent = contentComponent || Content
  const GalleryOptions = {
    buttons: {
      showDownloadButton: false,
      showThumbnailsButton: false,
    },
    progressBar: {
      showProgressBar: false
    },
    settings: {
      disablePanzoom: true,
      autoplaySpeed: 5000,
    }
  }
  return (
    <section className="section section--gradient content">
      <div className="container">
        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          {title}
        </h2>
        <PageContent className="content" content={content} />
        
        <Gallery 
          shareButton={false}
          fullscreenButton={false}
          zoomButton={false}
        >
          <div className="columns is-multiline">
        
        {Object.keys(publicURLs).map(i => (
          <div className="column is-3">
    <Item
      title={publicURLs[i].title}
      original={publicURLs[i].img}
      thumbnail={publicURLs[i].img}
      width={publicURLs[i].width}
      height={publicURLs[i].height}
      
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} src={publicURLs[i].img} style={{
        objectFit: 'cover',
    width: '300px',
    height: '300px',
        }} />
      )}
    </Item>
    </div>
        ))}
    
    </div>
  </Gallery>
      
      </div>
    </section>
  )
}

GalleryPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const GalleryPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { galleriesQuery: gallery } = data
  let publicURLs = buildArray(gallery.edges);
  function buildArray(array) {
    var newArray = new Array();
    for (var i = 0; i < array.length; i++) {
      let title = ''
      if (array[i].node.name === "001-HW1_6043") {
        title=`<p>Team GB take the team silver medal, at The FEI World Dressage Team Championship, Medal Ceremony. Herning, Denmark 2022</p><p>Gareth Hughes, Charlotte Dujardin, Charlotte Fry, Performance Manager - Caroline Griffith and Richard Davison.</p><p>Copyright 2022 Kevin Sparrow, all rights reserved.</p>   `
        }
        if (array[i].node.name === "002-22-FEIWCPratoni-NZL-Team-9495") {
          title=`<p>New Zealand take the team bronze medal, at the FEI World Championships for Eventing - Pratoni, Italy, 2022.</p><p>Tim Price; Monica Spencer, Clarke Johnstone, Jonelle Price and ESNZ High Performance General Manager, Jonathan Paget.</p><p>Copyright: Photo: Libby Law Photography</p>`
        }
        if (array[i].node.name === "003-2022-FEIWEC-GBR-Fry-6276") {
          title=`<p>Charlotte Fry GBR, rides Glamourdale, during The FEI World Dressage Individual Championship Grand Prix, Freestyle Medal Ceremony. Herning, Denmark 2022.<p><p>Copyright: Libby Law Photography</p>`
        }
        if (array[i].node.name === "004-22-FEIWCPratoni-NZL-Johnstone-6552") {
          title=`<p>Clarke Johnstone NZL, rides Menlo Park, during the Cross Country, at The FEI World Championships for Eventing — Pratoni, Italy 2022.</p><p>Copyright: Libby Law Photography</p>`
        }
        if (array[i].node.name === "005-HW1_7905") {
          title=`<p>Charlotte Dujardin GBR, rides Imhotep, during The FEI World Dressage Championship, Herning, Denmark 2022</p><p>Copyright 2022 Kevin Sparrow, all rights reserved.</p>`
        }
        if (array[i].node.name === "006-22-FEIWCPratoni-NZL-Price-5595") {
          title=`<p>Tim Price NZL, rides Falco, during the Cross Country, at The FEI World Championships for Eventing—Pratoni, Italy, 2022.</p><p>Copyright: Libby Law Photography</p>`
        }
        if (array[i].node.name === "007-HW1_9997") {
          title=`<p>Gareth Hughes GBR, rides Classic Briolinca, during The FEI World Dressage Championship Grand Prix, Herning, Denmark 2022</p><p>Copyright 2022 Kevin Sparrow, all rights reserved.</p>`
        }
        if (array[i].node.name === "008-22-FEIWCPratoni-NZL-Price-5313") {
          title=`<p>Jonelle Price NZL, rides McClaren, during the Cross Country, at The FEI World Championships for Eventing—Pratoni, Italy, 2022.</p><p>Copyright: Libby Law Photography</p>`
        }
        if (array[i].node.name === "009-HW1_0605") {
          title=`<p>Charlotte Fry GBR, rides Glamourdale, during The FEI World Dressage Championship Grand Prix, Herning, Denmark 2022.</p><p>Copyright 2022 Kevin Sparrow, all rights reserved.</p>`
        }
        if (array[i].node.name === "010-22-FEIWCPratoni-NZL-Price-6596") {
          title=`<p>Tim Price NZL, rides Falco, during the individual bronze medal ceremony, at The FEI World Championships for Eventing — Pratoni, Italy, 2022.</p><p>Copyright: Libby Law Photography</p>`
        }
        if (array[i].node.name === "011-21-CSIOB-USA-Kraut-2513") {
          title=`<p>Laura Kraut USA, rides Baloutinue, during the FEI Jumping Nations Cup"" Final Competition. 2021 Spain.</p><p>Copyright: Libby Law Photography</p>`
        }
        if (array[i].node.name === "012-HW1_1171") {
          title=`<p>Charlotte Fry GBR , gold medal, Cathrine Laudrup -Dufour DEN, silver medal and Dinja van Liere NED, bronze medal - during The FEI World Dressage Individual Championship Grand Prix, Freestyle Medal Ceremony. Herning, Denmark 2022</p><p>Copyright 2022 Kevin Sparrow, all rights reserved.</p>`
        }
      newArray.push({
        img: array[i].node.childImageSharp.fluid.src,
        title: title,
        width: array[i].node.childImageSharp.fluid.presentationWidth,
        height: array[i].node.childImageSharp.fluid.presentationHeight
      });
    }
    //console.log(newArray);
    return newArray;
  }  

  return (
    <Layout>
      <GalleryPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        images={gallery.edges}
        publicURLs={publicURLs}
      />
    </Layout>
  )
}

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GalleryPage

export const GalleryPageQuery = graphql`
  query GalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    galleriesQuery: allFile(
      filter: {relativePath: {glob: "gallery-imgs/*"}}
      sort: {order: ASC, fields: name}
    ) {
    edges {
      node {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            src
            presentationHeight
            presentationWidth
          }
        }
        publicURL
        name
      }
    }
  }
  }
`